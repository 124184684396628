// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-residences-club-js": () => import("./../src/pages/residences-club.js" /* webpackChunkName: "component---src-pages-residences-club-js" */),
  "component---src-pages-residences-js": () => import("./../src/pages/residences.js" /* webpackChunkName: "component---src-pages-residences-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

